import React from 'react'

import { Helmet } from 'react-helmet'
import { Seo, SeoProps } from './Seo'

interface LayoutProps {
  seo?: Partial<SeoProps>
}

export const Layout: React.FC<LayoutProps> = ({ children, seo }) => {
  return (
    <>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Fira+Mono:wght@400;700&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <Seo {...seo} />
      {children}
    </>
  )
}
