import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import { SeoTwitter } from './SeoTwitter'
import { SeoQuery } from '../../graphql-types'

export interface SeoProps {
  title?: string
  desc?: string
  banner?: string
  pathname?: string
  article?: boolean
  node?: Record<string, unknown>
}

export const Seo: React.FC<SeoProps> = ({
  title,
  desc,
  banner,
  pathname,
  article,
  node,
}) => {
  const { site } = useStaticQuery<SeoQuery>(query)

  const {
    url,
    defaultTitle,
    titleTemplate,
    defaultDescription,
    defaultBanner,
    headline,
    language,
    author,
    twitter,
    analyticsId,
  } = site?.siteMetadata || {}

  const seo = {
    title: (title
      ? titleTemplate?.replace('%s', title)
      : defaultTitle) as string,
    description: desc || defaultDescription,
    image: `${url}/${banner || defaultBanner}`,
    url: `${url}/${pathname || ''}`,
  }

  // schema.org in JSONLD format
  // https://developers.google.com/search/docs/guides/intro-structured-data
  // You can fill out the 'author', 'creator' with more data or another type (e.g. 'Organization')

  const schemaOrgWebPage = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    'url': url,
    headline,
    'inLanguage': language,
    'mainEntityOfPage': url,
    'description': defaultDescription,
    'name': defaultTitle,
    'author': {
      '@type': 'Organization',
      'name': author,
    },
    'copyrightHolder': {
      '@type': 'Organization',
      'name': author,
    },
    'copyrightYear': '2020',
    'creator': {
      '@type': 'Organization',
      'name': author,
    },
    'publisher': {
      '@type': 'Organization',
      'name': author,
    },
    'datePublished': '2020-12-01T10:30:00+01:00',
    'dateModified': site?.buildTime,
    'image': {
      '@type': 'ImageObject',
      'url': `${url}${defaultBanner}`,
    },
  }

  // Initial breadcrumb list
  const itemListElement = [
    {
      '@type': 'ListItem',
      'item': {
        '@id': url,
        'name': 'Homepage',
      },
      'position': 1,
    },
  ]

  const schemaArticle = article
    ? {
        '@context': 'http://schema.org',
        '@type': 'Article',
        'author': {
          '@type': 'Person',
          'name': author,
        },
        'copyrightHolder': {
          '@type': 'Person',
          'name': author,
        },
        'copyrightYear': '2019',
        'creator': {
          '@type': 'Person',
          'name': author,
        },
        'publisher': {
          '@type': 'Organization',
          'name': author,
          'logo': {
            '@type': 'ImageObject',
            'url': `${url}${defaultBanner}`,
          },
        },
        'datePublished': node?.first_publication_date,
        'dateModified': node?.last_publication_date,
        'description': seo.description,
        'headline': seo.title,
        'inLanguage': language,
        'url': seo.url,
        'name': seo.title,
        'image': {
          '@type': 'ImageObject',
          'url': seo.image,
        },
        'mainEntityOfPage': seo.url,
      }
    : null

  if (article) {
    // Push current blogpost into breadcrumb list
    itemListElement.push({
      '@type': 'ListItem',
      'item': {
        '@id': seo.url,
        'name': seo.title,
      },
      'position': 2,
    })
  }

  const breadcrumb = {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    'description': 'Breadcrumbs list',
    'name': 'Breadcrumbs',
    itemListElement,
  }

  return (
    <>
      <Helmet title={seo.title} defer={false}>
        <html lang={language as string} />
        <meta name="description" content={seo.description as string} />
        <meta name="image" content={seo.image} />
        <meta name="gatsby-starter" content="Gatsby Starter Prismic" />
        {/* Insert schema.org data conditionally (webpage/article) + everytime (breadcrumbs) */}
        {!article && (
          <script type="application/ld+json">
            {JSON.stringify(schemaOrgWebPage)}
          </script>
        )}
        {article && (
          <script type="application/ld+json">
            {JSON.stringify(schemaArticle)}
          </script>
        )}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${analyticsId}`}
        ></script>
        <script>
          {`window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', '${analyticsId}');`}
        </script>
      </Helmet>
      <SeoTwitter
        title={seo.title}
        image={seo.image}
        desc={seo.description}
        username={twitter}
      />
    </>
  )
}

const query = graphql`
  query Seo {
    site {
      buildTime(formatString: "YYYY-MM-DD")
      siteMetadata {
        url: siteUrl
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        defaultBanner: banner
        headline
        language
        locale
        author
        twitter
        analyticsId
      }
    }
  }
`
